'use strict';

/**
 * Init banner redirect to url feature
 */
function bannerRedirect() {
    $(document).ready(function () {
        $('.hero-banner-overlay-wrapper').on('click', function () {
            var $this = $(this);
            // As of jQuery 1.6, the .attr() method returns undefined for attributes that have not been set.
            if ($this.attr('data-bannerurl') !== undefined) {
                window.location.href = $this.attr('data-bannerurl');
            }
        });
    });
}

module.exports = { bannerRedirect };
